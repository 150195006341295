import { tokens } from "@bees-grow-shared/theme";
import { Carousel, SkeletonLoader } from "@hexa-ui/components";
import styled from "styled-components";

import * as StyledTaskCard from "./components/taskCard/TaskCard.styles";

export const Container = styled.div`
  gap: ${tokens.measure.space["spacing-2"]};
  height: fit-content;
  display: flex;
  flex-direction: column;

  > .carousel-root > .carousel > button {
    border: ${tokens.measure["border-width"].hairline} solid
      ${tokens.color.foundation.border.default};
    background: ${tokens.color.component.bg.button.secondary.default};
    box-shadow: ${tokens.shadow["elevation-2"]};
    z-index: 1;
  }

  > .carousel-root > .carousel > .control-dots {
    margin-bottom: 0;
  }

  div:has(> div > ${StyledTaskCard.Container}) {
    gap: ${tokens.measure.space["spacing-2"]};
    padding-left: ${tokens.measure.space["spacing-8"]};
    padding-right: ${tokens.measure.space["spacing-8"]};
    padding-bottom: ${tokens.measure.space["spacing-4"]};
    padding-top: ${tokens.measure.space["spacing-2"]};
  }

  div:has(> ${StyledTaskCard.Container}) {
    box-shadow: ${tokens.shadow["elevation-2"]};
  }
`;

export const ContainerError = styled.div`
  gap: ${tokens.measure.space["spacing-4"]};
  display: flex;
  flex-direction: column;
`;

export const ContainerLoading = styled.div`
  gap: ${tokens.measure.space["spacing-2"]};
  display: flex;
  flex-direction: column;
`;

export const CarouselRoot = styled(Carousel.Root)``;

export const TasksCarouselTitleContainerLoading = styled.div`
  padding: 0.125rem 0;
`;

export const TasksCarouselTitle = styled(SkeletonLoader)`
  height: 1.25rem;
  width: 13.625rem;
`;

export const TasksCarouselLoading = styled.ul`
  gap: ${tokens.measure.space["spacing-2"]};
  width: 100%;
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
`;

export const TaskCardLoading = styled.li`
  gap: ${tokens.measure.space["spacing-2"]};
  background-color: ${tokens.color.component.bg.card.neutral.weak};
  box-shadow: ${tokens.shadow["elevation-2"]};
  border-radius: ${tokens.measure["border-radius"]["borderradius-2"]};
  padding: ${tokens.measure.space["spacing-4"]};
  height: 4.75rem;
  width: 100%;
`;

export const CardTextContainerLoading = styled.div`
  padding: 0.1875rem 0;
`;

export const CardTitleLoading = styled(SkeletonLoader)`
  height: 0.875rem;
  width: 8.1875rem;
`;

export const CardDescriptionLoading = styled(SkeletonLoader)`
  height: 0.875rem;
  width: 11.1875rem;
`;

export const ContainerEmpty = styled.div`
  gap: ${tokens.measure.space["spacing-4"]};
  display: flex;
  flex-direction: column;
`;
