import { useIntl } from "react-intl";

import { ItemCard } from "@bees-grow-shared/business-components";
import { Mission } from "@bees-grow-shared/services";

import EmptyStateMediumIcon from "@/assets/EmptyStateMediumIcon";
import { SCREEN_NAME } from "@/config/constants";

import MarkAsDone from "./components/markAsDone/MarkAsDone";
import * as Styled from "./DrawerBody.styles";

interface DrawerBodyProps {
  task: Mission;
  children: React.ReactNode;
  onRetry: () => void;
}

export default function DrawerBody({
  task,
  children,
  onRetry,
}: DrawerBodyProps) {
  const { status, products, combos } = task;

  if (status?.isLoading) {
    return <LoadingBehavior />;
  }

  if (status?.error) {
    return (
      <ErrorBehavior errorMessage={status?.errorMessage} onRetry={onRetry} />
    );
  }

  return (
    <Styled.Container data-testid="drawer-body">
      <Styled.Script data-testid="drawer-body-script">{children}</Styled.Script>
      <MarkAsDone task={task} />
      {combos?.map((combo) => (
        <Styled.ItemsContainer key={combo.platformId}>
          <ItemCard.Combo
            item={combo}
            hideShowDetails
            analyticsData={{
              category: null,
              isProductTask: true,
              screenName: SCREEN_NAME,
            }}
          />
        </Styled.ItemsContainer>
      ))}
      {products?.map((product) => (
        <Styled.ItemsContainer key={product.variants[0].platformId}>
          <ItemCard.Product
            item={product}
            hideShowDetails={true}
            analyticsData={{
              category: null,
              isProductTask: true,
              screenName: SCREEN_NAME,
            }}
          />
        </Styled.ItemsContainer>
      ))}
    </Styled.Container>
  );
}

function LoadingBehavior() {
  return (
    <Styled.ContainerLoading data-testid="drawer-body-loading">
      <Styled.LineLoading />
      <Styled.LineLoading />
      <Styled.LineLoading />
      <Styled.LineLoading />
      <Styled.HalfLineLoading />
    </Styled.ContainerLoading>
  );
}

function ErrorBehavior({ errorMessage, onRetry }) {
  const { formatMessage } = useIntl();

  return (
    <Styled.ContainerError data-testid="drawer-body-error">
      <Styled.ContainerMessageIconError>
        <EmptyStateMediumIcon />
        <Styled.ParagraphError>{errorMessage}</Styled.ParagraphError>
      </Styled.ContainerMessageIconError>
      <Styled.ReloadError
        variant="secondary"
        elevated={false}
        onClick={onRetry}
      >
        {formatMessage({ id: "TasksCarousel.TaskDrawer.Error.RELOAD" })}
      </Styled.ReloadError>
    </Styled.ContainerError>
  );
}
