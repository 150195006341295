import { accountSharedService, Mission } from "@bees-grow-shared/services";

import { intl } from "@/i18n";
import { getMissionDetails } from "@/services/mission/getMissionDetails/GetMissionDetailsService";

import { getMissionDetailsConverter } from "./converters/GetMissionDetailsConverter";

interface GetMissionDetailsProps {
  missionKey: string;
}
export async function getMissionDetailsUseCase({
  missionKey,
}: GetMissionDetailsProps): Promise<
  Omit<Mission, "effectiveness" | "isSponsored">
> {
  const accountService = accountSharedService();
  const { accountId, vendorId } = accountService.getStoreValue();
  try {
    const missionDetailsResponse = await getMissionDetails({
      query: {
        accountId,
        missionKey,
      },
      headers: { vendorId },
    });

    return {
      ...getMissionDetailsConverter(missionDetailsResponse),
      status: {
        done: true,
        isLoading: false,
        error: false,
      },
    };
  } catch (e) {
    throw new Error(
      intl.formatMessage({
        id: "TasksCarousel.TaskDrawer.Error.GENERIC_ERROR",
      }),
    );
  }
}
