import { useIntl } from "react-intl";

import { Mission } from "@bees-grow-shared/services";
import { TextButton } from "@hexa-ui/components";
import { Check, X } from "@hexa-ui/icons";

import { useMarkAsDone } from "./hooks/useMarkAsDone/useMarkAsDone";
import * as Styled from "./MarkAsDone.styles";

interface MarkAsDoneProps {
  task: Mission;
}

export default function MarkAsDone({ task }: MarkAsDoneProps) {
  const { formatMessage } = useIntl();
  const { missionKey, effectiveness, name } = task;
  const { handleMarkAsDoneClick, handleMarkAsNotDoneClick } = useMarkAsDone({
    missionKey,
    name,
    priority: 1,
  });

  return (
    <Styled.Container>
      <Styled.Button
        leading
        icon={() => <Check size="medium" />}
        size="medium"
        disabled={effectiveness}
        onClick={handleMarkAsDoneClick}
        data-testid="mark-as-done-button"
      >
        {formatMessage({
          id: "TasksCarousel.TaskDrawer.MARK_AS_DONE",
        })}
      </Styled.Button>
      {effectiveness && (
        <TextButton onClick={handleMarkAsNotDoneClick}>
          <Styled.TextButtonContent data-testid="mark-as-done-text-button">
            <Styled.CloseIcon>
              <X size="medium" />
            </Styled.CloseIcon>
            {formatMessage({
              id: "TasksCarousel.TaskDrawer.MARK_AS_NOT_DONE",
            })}
          </Styled.TextButtonContent>
        </TextButton>
      )}
    </Styled.Container>
  );
}
