import { DrawerGeneric } from "@bees-grow-shared/components";
import { Mission } from "@bees-grow-shared/services";
import { MessageCircle } from "@hexa-ui/icons";

import CardDescription from "./components/cardDescription/CardDescription";
import CardTitle from "./components/cardTitle/CardTitle";
import DrawerBody from "./components/drawerBody/DrawerBody";
import DrawerHeader from "./components/drawerHeader/DrawerHeader";
import { useTaskCard } from "./hooks/useTaskCard";
import * as Styled from "./TaskCard.styles";

interface TaskCardProps {
  task: Mission;
  isSponsored?: boolean;
  priority: number;
}

export default function TaskCard({
  task,
  isSponsored,
  priority,
}: TaskCardProps) {
  const { missionKey, name, description, type, status, script, effectiveness } =
    task;
  const {
    descriptionFormatted,
    titleFormatted,
    scriptFormatted,
    drawerOpen,
    setDrawerOpen,
    getMissionDetails,
  } = useTaskCard({
    missionKey,
    title: name,
    description,
    type,
    script,
    priority,
  });

  return (
    <Styled.Container data-testid="task-card" effectiveness={effectiveness}>
      <Styled.Content>
        <CardTitle title={titleFormatted} isSponsored={isSponsored} />
        <CardDescription description={descriptionFormatted} />
      </Styled.Content>
      <DrawerGeneric
        header={<DrawerHeader status={status} title={titleFormatted} />}
        body={
          <DrawerBody
            task={{ ...task, name: titleFormatted }}
            onRetry={getMissionDetails}
          >
            {scriptFormatted}
          </DrawerBody>
        }
        open={drawerOpen}
        setOpen={setDrawerOpen}
        showCloseButton={!status?.isLoading}
      >
        <Styled.DetailsButton
          data-testid="task-card-details-button"
          variant="tertiary"
          icon={MessageCircle}
          onClick={() => setDrawerOpen(true)}
        />
      </DrawerGeneric>
    </Styled.Container>
  );
}
